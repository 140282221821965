@import '../../../pages/App/sass/variables.scss';

.title {
  margin: 0;
  line-height: 1.15;
  font-size: 3rem;
  text-align: center;
  font-family: $main-font-family;

  a {
    color: #0070f3;
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
    }
  }
}

.dashboardTitle {
  font-weight: 700;
  font-size: 40px;
  line-height: 140%;
  text-align: left;
  margin-bottom: 24px;
  @media #{$tablets} {
    font-size: 24px;
    margin-bottom: 16px;
  }
}

.afterCourseTitle, .courseInfoTitle {
  font-weight: 700;
  font-size: 40px;
  line-height: 140%;
  color: $defaultText;
  text-align: left;
  @media only screen and (max-width: $screen-xs) {
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
  }
}
.calendarTitle {
  font-weight: 700;
  font-size: 40px;
  line-height: 140%;
  color: $defaultText;
  text-align: left;
  margin-bottom: 24px;
  @media #{$phones} {
    font-size: 24px;
    font-weight: 500;
    line-height: 34px;
    text-align: center;
  }
}

.tableLayoutTitle {
  text-align: left;
  font-size: 40px;
  font-weight: 700;
  line-height: 56px;

  @media #{$phones} {
    font-size: 24px;
    font-weight: 500;
    line-height: 34px;
  }
}

.supportTitle {
  font-size: 28px;

  @media #{$phones} {
    font-size: 22px;
  }
}
